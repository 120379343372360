import { useState, useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext, tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InventoryIcon from '@mui/icons-material/Inventory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DynamicFormRoundedIcon from '@mui/icons-material/DynamicFormRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CachedIcon from '@mui/icons-material/Cached';
import { SvgIcon } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import LineDetailsIcon from '@mui/icons-material/LineStyle';

const TrendingUpDay1Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
    <text x="20" y="22" fontSize="12" textAnchor="end" fill="currentColor">1</text>
  </SvgIcon>
);

const TrendingUpDay30Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
    <text x="22" y="22" fontSize="12" textAnchor="end" fill="currentColor">30</text>
  </SvgIcon>
);

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [showIcons, setShowIcons] = useState(false);


  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };
  
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "2px 15px 3px 30px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        "& .settings-row": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
          position: "absolute",
          bottom: 0,
          left: 2,
          right: 0,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* Title and Collapse Button */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="1px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Encasa HQ
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="SB Dashboard"
              to="/sb_dashboard"
              icon={<BarChartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Cataloging
            </Typography>
            <Item
              title="Lines Details"
              to="/catalog_lines/list"
              icon={<CategoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Size Variations"
              to="/catalog_size_variations/list"
              icon={<FormatSizeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Grouping"
              to="/catalog_tools/list"
              icon={<GroupWorkIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Listing Logs"
              to="/logs/list"
              icon={<HistoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Digital Asset Management"
              to="/dam"
              icon={<ImageIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Marketing
            </Typography>
            <Item
              title="Variations"
              to="/variations/list"
              icon={<ShoppingCartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Returns Reasons"
              to="/returns"
              icon={<AssignmentReturnIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="SKU Details"
              to="/cosp"
              icon={<InventoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Details"
              to="/line_details"
              icon={<LineDetailsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Daily"
              to="/line_daily"
              icon={<TrendingUpDay1Icon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Monthly"
              to="/line_monthly"
              icon={<TrendingUpDay30Icon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Keyword Explorer"
              to="/keyword_explorer"
              icon={<ExploreIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Operations
            </Typography>
            <Item
              title="Line Fulfilment Fee"
              to="/fulfilment_fee/line"
              icon={<LocalShippingIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Cache Clearing"
              to="/cache-clearing"
              icon={<CachedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>

          <Box 
    className="settings-row"
    sx={{  flexDirection: 'column-reverse', alignItems:'flex-start', position: 'absolute', marginBottom: '25px' , bottom: 20, left: 0  }}
  >
    <IconButton 
      onClick={toggleIcons} 
      sx={{ color: colors.grey[100], marginBottom: '0px', left : 8, position : 'absolute', bottom : -8 }}
    >
      <SettingsOutlinedIcon />
    </IconButton>
    {showIcons && (
      <Box
        sx={{ display: 'flex', flexDirection: 'column-reverse', backgroundColor: colors.primary[400], 
          padding: '8px', borderRadius: '4px', position: 'absolute', bottom: '100%',left : 0}}
      >
        <IconButton 
          onClick={colorMode.toggleColorMode} 
          sx={{ color: colors.grey[100] }} 
        >
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton sx={{ color: colors.grey[100] }}>
          <NotificationsOutlinedIcon />
        </IconButton>
        {/* <IconButton sx={{ color: colors.grey[100] }}>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <IconButton sx={{ color: colors.grey[100] }}>
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleLogout} sx={{ color: colors.grey[100] }}>
          <ExitToAppIcon />
        </IconButton>
      </Box>
    )}
  </Box>
     </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;