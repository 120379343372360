import React, { useState, useCallback } from 'react';
import { Box, Button, Typography, Snackbar } from '@mui/material';

const CacheClearingPanel = () => {
  const [messages, setMessages] = useState({
    cosp: '',
    linecache: '',
    // lineMonthly: ''
  });

  const handleClearCOSPCache = useCallback(() => {
    fetch('/api/cosp/clear_cosp_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear COSP cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, cosp: data.message }));
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, cosp: `Error clearing COSP cache: ${error.message}` }));
      });
  }, []);

  const handleClearLineCache = useCallback(() => {
    fetch('/api/line/clear_line_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear Line Daily cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, lineDaily: data.message }));
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, lineDaily: `Error clearing Line Daily cache: ${error.message}` }));
      });
  }, []);

  const [refreshMessages, setRefreshMessages] = useState({});
  const handleRefreshMaterializedSpapiTable = useCallback(() => {
    setRefreshMessages(prev => ({ ...prev, spapiTable: 'Refreshing Materialized View...' }));
    
    fetch('/api/line/refresh_materialized_spapi_table', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh Materialized SP-API table');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setRefreshMessages(prev => ({ ...prev, spapiTable: data.message }));
      })
      .catch(error => {
        console.error('Error:', error);
        setRefreshMessages(prev => ({ ...prev, spapiTable: `Error refreshing Materialized SP-API table: ${error.message}` }));
      });
  }, []);

  const handleRefreshMaterializedHQCOSP = useCallback(() => {
    setRefreshMessages(prev => ({ ...prev, hqCosp: 'Refreshing Materialized HQ COSP View...' }));
    
    fetch('/api/line/refresh_materialized_HQ_COSP', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh Materialized HQ COSP table');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setRefreshMessages(prev => ({ ...prev, hqCosp: data.message }));
      })
      .catch(error => {
        console.error('Error:', error);
        setRefreshMessages(prev => ({ ...prev, hqCosp: `Error refreshing Materialized HQ COSP table: ${error.message}` }));
      });
  }, []);
  
  return (
    <Box mt={2} sx={{ padding: 2 }}>
    <Typography variant="h3" gutterBottom>
      Cache Clearing Panel :
    </Typography>
    <Box sx={{ paddingLeft: 4 }} style={{ flexFlow: 'column noWrap'}}>
    <Typography style={{alignSelf: 'flex-end'}} variant='h6'>
      Use these buttons once or twice a day to clear specific cache when its data appears to be outdated.
      After clearing the cache, refresh the page to retrieve the most recent data.
    <Typography  variant='h5' style={{color: 'blue' }}>
    Don't use frequently  as it affects all users.
    </Typography>
    </Typography>    
      <Box mt={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
          variant="contained"   color="secondary"  
          onClick={handleClearCOSPCache}
          sx={{  width: '240px',  fontWeight: 'bold',  display: 'flex',justifyContent: 'center', alignItems: 'center', }}
        > 
          Clear COSP Cache
        </Button>

        <Button 
          variant="contained"   color="secondary" 
          onClick={handleClearLineCache}
          sx={{  width: '240px',  fontWeight: 'bold',  display: 'flex',justifyContent: 'center', alignItems: 'center',}}
        >
          Clear Line Daily/Monthly Cache
        </Button> 
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleRefreshMaterializedSpapiTable}
          sx={{ width: '240px',  fontWeight: 'bold',  display: 'flex',justifyContent: 'center',
            alignItems: 'center', mt: 3, bgcolor: 'pink', '&:hover': { bgcolor: '#F33A6A', },
          }}
        >
          Refresh Materialized SP-Api Table
          </Button>
      <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleRefreshMaterializedHQCOSP}
          sx={{  width: '240px',  fontWeight: 'bold',  display: 'flex', justifyContent: 'center',
            alignItems: 'center',  bgcolor: 'pink','&:hover': { bgcolor: '#F33A6A', },  }}
        >
          Refresh Materialized HQ COSP
        </Button>
      </Box>
    </Box>

    {Object.entries(messages).map(([key, message]) => (
      message && (
        <Snackbar
          key={key}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => setMessages(prev => ({ ...prev, [key]: '' }))}
          message={message}
          sx={{  ml :'300px', mb : '100px'}}
        />
      )
    ))}

    {/* Refresh Materialized Messages */}
    {Object.entries(refreshMessages).map(([key, message]) => (
     message && (
      <Snackbar
        key={key}
        open={!!message}
        autoHideDuration={24000}
        onClose={() => setRefreshMessages(prev => ({ ...prev, [key]: '' }))}
        message={message}
        sx={{ ml :'300px', mb: '30px' }}
       />
        )
    ))}
  </Box>
);
};

export default CacheClearingPanel;
