import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { useEffect, useState } from 'react';
import { tokens } from "../../theme";

const FetchMessage = () => {
    const [message, setMessage] = useState('')
  
    useEffect(() => {
        fetch("/logs").then(
          res => res.json()
        ).then(
          data => {
              setMessage(data.message) // Assuming the message is stored in data.message
              console.log(data.message) // Log the actual message
          }
        )
      },[])
  
    return <Box mt="20px">{message}</Box>;
  };

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return <Box m="20 px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

            <FetchMessage />

        </Box>
    </Box>
};

export default Dashboard;