import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const LineDaily = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [lastFilledDay, setLastFilledDay] = useState(30);
  const [quickFilter, setQuickFilter] = useState('');
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');

  useEffect(() => {
    document.title = "Line Daily | Encasa HQ";
    console.log('Initiating Line Daily data fetch');
    fetch('/api/line/get_line_daily_data')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          const lastDay = findLastFilledDay(data);
          setLastFilledDay(lastDay);

          // Precompute values for p7, l7, delta, l30, and negL30
          const computedData = data.map(row => {
            const p7Days = Array.from({ length: 7 }, (_, i) => row[`day_${lastDay + 7 + i}`] || 0);
            const l7Days = Array.from({ length: 7 }, (_, i) => row[`day_${lastDay + i}`] || 0);
            const l30Days = Array.from({ length: 30 }, (_, i) => row[`day_${i+1}`] || 0);

            const p7 = p7Days.reduce((sum, value) => sum + value, 0);
            const l7 = l7Days.reduce((sum, value) => sum + value, 0);
            const delta = l7 - p7;
            const l30 = l30Days.reduce((sum, value) => sum + value, 0);
            const negL30 = l30Days.filter(value => value < 0).length;

            return {
              ...row,
              p7,
              l7,
              delta,
              l30,
              negL30
            };
          });

          setRowData(computedData);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Daily data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const columns = [
    { 
      headerName: "Country", 
      field: "country", 
      sortable: true, 
      filter: true, 
      width: 120, 
      pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value // Include in quick filter
    },
    { 
      headerName: "Line", 
      field: "Line", 
      sortable: true, 
      filter: true, 
      width: 120, 
      pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value // Include in quick filter
    },
    { 
      headerName: "Metric", 
      field: "metric", 
      sortable: true, 
      filter: true, 
      width: 150, 
      pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value // Include in quick filter
    },
    { 
      headerName: "DRI", 
      field: "dri", 
      sortable: true, 
      filter: true, 
      width: 100, 
      enableRowGroup: true,
      getQuickFilterText: params => params.value // Include in quick filter
    },
    { 
      headerName: "P7", 
      field: "p7", 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      width: 100,
      cellStyle: { textAlign: 'right' },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      aggFunc: 'sum',
      getQuickFilterText: () => '' // Exclude from quick filter
    },
    { 
      headerName: "L7", 
      field: "l7", 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      width: 100,
      cellStyle: { textAlign: 'right' },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      aggFunc: 'sum',
      getQuickFilterText: () => '' // Exclude from quick filter
    },
    {
      headerName: "Delta",
      field: "delta",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 100,
      cellStyle: params => ({
        textAlign: 'right',
        color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black'
      }),
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      aggFunc: 'sum',
      getQuickFilterText: () => '' // Exclude from quick filter
    },
    { 
      headerName: "L30", 
      field: "l30", 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      width: 100,
      cellStyle: { textAlign: 'right' },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      aggFunc: 'sum',
      getQuickFilterText: () => '' // Exclude from quick filter
    },
    { 
      headerName: "Neg L30", 
      field: "negL30", 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      width: 100,
      cellStyle: { textAlign: 'right' },
      getQuickFilterText: () => '' // Exclude from quick filter
    },
    ...Array.from({ length: 30 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (i+1));  
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      return {
        headerName: formattedDate,
        field: `day_${i+1}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        width: 100,
        cellStyle: { textAlign: 'right' },
        aggFunc: 'sum',
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getQuickFilterText: () => '' // Exclude from quick filter
      };
    }),
  ];
  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilter('');
    setAppliedQuickFilter('');
  }, []);

  const handleApplyCustomFilter = useCallback(async () => {
    console.log('Applying custom filter...');
    if (gridRef.current && gridRef.current.api) {
      console.log('Grid API is available');
      const filterModel = {
        metric: {
          filterType: 'set',
          values: ['Net Profit'],
          filter: ['Net Profit']
        },
        [`day_${lastFilledDay}`]: {
          filterType: 'number',
          type: 'notBlank'
        }
      };
      console.log('Filter model:', filterModel);
      gridRef.current.api.setFilterModel(filterModel);
      
      gridRef.current.api.onFilterChanged();
      console.log('Filter applied');

      // Sort by last filled day column
      const sortModel = [
        {
          colId: `day_${lastFilledDay}`,
          sort: 'asc'
        }
      ];
      console.log('Sort model:', sortModel);
      gridRef.current.api.applyColumnState({ state: sortModel });
      console.log('Sorting applied');
    } else {
      console.log('Grid API is not available');
    }
  }, [lastFilledDay]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: null,
    };
  }, []);

  const handleQuickFilterChange = useCallback((event) => {
    setQuickFilter(event.target.value);
  }, []);

  const handleQuickFilterKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      setAppliedQuickFilter(quickFilter);
    }
  }, [quickFilter]);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Daily</Typography>
        <Box>
          <TextField
            label="Quick Filter"
            variant="outlined"
            size="small"
            value={quickFilter}
            onChange={handleQuickFilterChange}
            onKeyPress={handleQuickFilterKeyPress}
            style={{ marginRight: '10px' }}
          />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleApplyCustomFilter}
            style={{ marginRight: '10px' }}
          >
            Filter to Profits
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              cellRenderer: 'agCellRendererHtml',
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            grandTotalRow="bottom"
            groupDefaultExpanded={0} // Collapse all groups by default
            autoGroupColumnDef={{
              headerName: 'Group',
              minWidth: 250,
              cellRendererParams: {
                suppressCount: true,
              },
            }}
            quickFilterText={appliedQuickFilter}
            cacheQuickFilter={true}
          />
        </div>
      )}
    </div>
  );
};

// Add this helper function at the end of the component, before the return statement
const findLastFilledDay = (data) => {
  for (let i = 1; i <= 30; i++) {
    if (data.some(row => row[`day_${i}`] !== undefined && row[`day_${i}`] !== null)) {
      return i;
    }
  }
  return 1; // Default to 1 if no filled day is found
};

export default LineDaily;