import React, { useState } from 'react';
import { Box, TextField, Button, Select, MenuItem, Checkbox, ListItemText, Typography } from '@mui/material';
import { Tooltip, IconButton } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { AgGridReact } from 'ag-grid-react';
import { useSpApiData } from './spapiDataContext';

const SpApiGetData = () => {
  const { spApiData, setSpApiData } = useSpApiData();
  const [countryListingAPI, setCountryListingAPI] = useState('');
  const [skusListingAPI, setSkusListingAPI] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [feedbackListingAPI, setFeedbackListingAPI] = useState({ message: '', style: {} });
   
  const handleGetListingItem = async () => {
    setFeedbackListingAPI({ message: 'Fetching listing item...', style: { color: 'blue' } });
    try {
      const response = await fetch('/api/spapi_getdata/sku/getListingItem', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: countryListingAPI,
          skus: skusListingAPI.split(',').map(sku => sku.trim()),
          includedData: selectedOptions.join(',')
        })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setFeedbackListingAPI({ message: 'Data fetched successfully', style: { color: 'green' } });
        // setgetListingAPI(result);
        setSpApiData(result);
      } else {
        setFeedbackListingAPI({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setFeedbackListingAPI({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };

  const options = ['summaries','issues','attributes'];

  return (
    <Box mt={1}>
         <h3 style={{ marginBottom: '10px' }}>
          Get issues associated with the listing items
          <Tooltip title="Use any of these attributes under Include Data button as per requirement : summaries,issues,attributes">
          <IconButton size="small" style={{ marginLeft: '5px',  padding: '2px', verticalAlign: 'center',  marginTop: '-3px' }}>
            <InfoIcon fontSize="medium" style={{ color: '#4682b4' , fontSize : '18px'}}/>
          </IconButton>
        </Tooltip>
         </h3>
         <Box display="flex" alignItems="center" mb={2}>
        <TextField
        label="Country"
        value={countryListingAPI}
        onChange={(e) => setCountryListingAPI(e.target.value)}
        style={{ marginRight: '10px' }}
        />
       <TextField
       label="SKUs"
       value={skusListingAPI}
       onChange={(e) => setSkusListingAPI(e.target.value)}
       style={{ marginRight: '10px' }}
      />
      {/* <TextField
          label="Include Data"
          value={selectedOptions.join(',')}
          onChange={(e) => setSelectedOptions(e.target.value.split(','))}
          style={{ marginRight: '10px' }}
        /> */}
        <Select
          multiple
          displayEmpty
          value={selectedOptions}
          onChange={(e) => setSelectedOptions(e.target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Please select</em>;
            }
            return selected.join(', ');
          }}
          style={{ marginRight: '10px', minWidth: 150 }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedOptions.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleGetListingItem}
          style={{ marginRight: '10px' , fontWeight : 'bold'}}
        >
          Get Listing API Data
          </Button>
        </Box>
        {feedbackListingAPI.message && (
          <Typography style={feedbackListingAPI.style} mb={2}>
            {feedbackListingAPI.message}
          </Typography>
        )}
        {spApiData && (
          <Box mt={0}>
          <Typography mb={1}></Typography>
          <Box 
            sx={{
              maxHeight: '475px', overflowY: 'auto', overflowX: 'auto',
              border: '2px solid DodgerBlue',
              borderRadius: '4px',
              padding: '10px',
              '&::-webkit-scrollbar': {
                width: '10px',height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',  borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}
          >
            <pre 
              style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(spApiData, null, 2).replace(
                  /"message":\s*"([^"]*)"/g,
                  (match, p1) => `"message": "<span style='color: red;'>${p1}</span>"`
                )
              }}
            />
          </Box>
        </Box>
      )}
      </Box>
  );
};


const GetLatestStatusComponent = () => {
  const { latestStatusData, setLatestStatusData } = useSpApiData();
  const [selectedCountryStatus, setSelectedCountryStatus] = useState('');
  const [lineStatus, setLineStatus] = useState('');
  const [getStatus, setGetStatus] = useState({ message: '', style: {} });
  // const [latestStatus, setLatestStatus] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const handleGetLatestStatus = async () => {
    console.log("Initiating Get Latest Status request");
    setGetStatus({ message: 'Fetching latest status...', style: { color: 'blue' } });
    try {
      const processedLines = lineStatus.split(/[\n, ]/).map(line => line.trim()).filter(line => line);

      console.log("Processed lines:", processedLines);
      console.log("Selected country:", selectedCountryStatus);

      const response = await axios.post('/api/spapi_getdata/sku/latest_status_df', {
        country: selectedCountryStatus,
        lines: processedLines
      });

      console.log("Response status:", response.status);

      if (response.status === 200) {
        setGetStatus({ message: 'Data fetched successfully', style: { color: 'green' } });
        // setLatestStatus(response.data.data);
        setLatestStatusData(response.data.data); 
      } else {
        setGetStatus({ message: `Error: ${response.data.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      console.error("Error:", error);
      setGetStatus({ message: `Error: ${error.response?.data?.error || error.message}`, style: { color: 'red' } });
    }
  };
  
  const getStatusValue = (params) => {
    if (!params.data) return ''; 
    const { ParentAsin, Relationship_Type } = params.data;
    if (!ParentAsin && !Relationship_Type) {
      return 'Ungrouped';
    } else if (ParentAsin && Relationship_Type) {
      return 'Grouped';
    }
    return '';
  };

  const columns = [
    {
      headerName: "Sr No", width: 55,pinned: 'left',cellStyle: { textAlign: 'right' },
      valueGetter: (params) => {
        return params.api.getDisplayedRowCount() > 0 ? 
          params.api.getRowNode(params.node.id).rowIndex + 1 : 
          0; },
      sortable: false,filter: false,
      // suppressMenu: true,
    },
    { headerName: "Country", field: "country", sortable: true, filter: true, width: 80, cellStyle: { textAlign: 'center' }, pinned: 'left' },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 95,  enableRowGroup: true, pinned: 'left' },
    { headerName: "Asin", field: "asin", sortable: true, filter: true, width: 140, pinned: 'left' },
    { headerName: "Part Number", field: "part_number", sortable: true, filter: true, width: 150, pinned: 'left' },
    { headerName: "Brand", field: "brand", sortable: true, filter: true, width: 120 },
    { headerName: "Status", field: "Status", sortable: true, filter: true, width: 120 ,
      cellRenderer: (params) => {
        const status = getStatusValue(params);
        let color = 'black'; // default color
        if (status === 'Grouped') {
          color = 'green';
        } else if (status === 'Ungrouped') {
          color = 'red';
        }
        return <span style={{ color: color }}>{status}</span>;
      }},
    { headerName: "Parent Asin", field: "ParentAsin", sortable: true, filter: true, width: 120 },
    { headerName: "Size", field: "size", sortable: true, filter: true, width: 120 },
    { headerName: "color", field: "color", sortable: true, filter: true, width: 120 },
    { headerName: "Pcs Pack", field: "number_of_items", sortable: true, filter: true, width: 120 },
    { headerName: "Variation Theme", field: "VariationTheme", sortable: true, filter: true, width: 120 },
    { headerName: "Product Type", field: "productType", sortable: true, filter: true, width: 120 },
    { headerName: "Pattern", field: "pattern", sortable: true, filter: true, width: 120 },
    { headerName: "Relationship Type", field: "Relationship_Type", sortable: true, filter: true, width: 120 }
  ];

  <DataGrid
    rows={latestStatusData}  columns={columns}
    pageSize={5}  rowsPerPageOptions={[5]}
    getRowId={(row) => row.sku + row.asin}  
  />
  
      // <Card elevation={10}>
    //   <CardContent>
    //     <h3 variant="h6" component="h2" style={{ marginTop: '0px', marginBottom: '10px' }}>
    //     Get Current Grouping Status From SP-API </h3>  
    return ( 
      <Box mt={0}>
        <h3 style={{ marginBottom: '10px' }}>Get Current Grouping Status From SP-API</h3>
        <Box display="flex" alignItems="center" mb={2}> 
          <div style={{ display: 'flex', alignItems: 'center', mb : '2' , fontWeight: 'bold' }}>
            <TextField
              label="Country"
              value={selectedCountryStatus}
              onChange={(e) => {
                console.log("Country changed:", e.target.value);
                setSelectedCountryStatus(e.target.value);
              }}
              style={{ marginRight: '10px' }} 
            />
            <TextField
              label="Lines (comma-separated)"
              value={lineStatus}
              onChange={(e) => {
                console.log("Lines changed:", e.target.value);
                setLineStatus(e.target.value);
              }}
              style={{ marginRight: '10px', width : '500px' }} 
            />
            <div style={{ display: 'flex', alignItems: 'center', height: '100%'  }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGetLatestStatus}
              >
                Get Latest Status
              </Button>
            </div>
          </div>
        </Box>
      
        {getStatus.message && (
         <Box mt={-2 } >
           <Typography style={getStatus.style}>
             {getStatus.message}
           </Typography>
         </Box>
       )}

        {/* <Typography style={getStatus.style}>{getStatus.message}</Typography> */}
        <div className="ag-theme-alpine" 
          style={{ 
            height: 530, 
            width: '100%',
            '--ag-header-background-color': '#4682b4',
            '--ag-odd-row-background-color': '#f8f8f8',
            '--ag-header-font-size': '25px',  
            '--ag-header-font-weight': 'bold', 
            '--ag-header-color': 'black',
          }}>
          <AgGridReact
            columnDefs={columns}
            rowData={latestStatusData}
            pagination={true}
            paginationPageSize={100}
            suppressCellSelection={true}
            onGridReady={(params) => {
              setGridApi(params.api);
            }}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              cellRenderer: 'agCellRendererHtml',
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            animateRows={true}
            enableCellTextSelection={true}
            rowSelection="single"
            getRowStyle={(params) => {
              if (params.node.rowIndex % 2 === 0) {
                return { background: '#f1f7f0' };
              }
            }}
          />
        </div>
      </Box>
    );
  };




export default SpApiGetData;
export { GetLatestStatusComponent };