import { Box, Tabs, Tab } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from 'react';

const Logs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [logs, setLogs] = useState([]);
  const [activeListingLogs, setActiveListingLogs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    fetch('/api/amazon_catalog/sku/listing_logs')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(response => {
        console.log('Response received from the server:', response);
        const logsData = JSON.parse(response.data);
        return logsData;
      })
      .then(data => setLogs(data.map((log, index) => ({ ...log, id: index }))))
      .catch(error => console.error('Error fetching logs:', error));
  }, []);

  const BQ_Log_Columns = [
    { field: "time_id", headerName: "Time ID", flex: 1 },
    { field: "country", headerName: "Country", flex: 1 },
    { field: "feedId", headerName: "Feed ID", flex: 1 },
    { field: "operationType", headerName: "Operation Type", flex: 1 },
    { field: "message_id", headerName: "Message ID", flex: 1 },
    { field: "sku", headerName: "SKU", flex: 1 },
    { field: "product_type", headerName: "Product Type", flex: 1 },
  ];
  
  useEffect(() => {
    if (activeTab === 1) {
      fetch('/api/amazon_catalog/sku/active_listing_logs')
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(response => {
          console.log('Active listing logs received from the server:', response);
          return response.data;
        })
        .then(data => setActiveListingLogs(data.map((log, index) => ({ ...log, id: index }))))
        .catch(error => console.error('Error fetching active listing logs:', error));
    }
  }, [activeTab]);
  
  const ListingActivityLogColumns = [
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'module', headerName: 'Module', width: 150 },
    { field: 'message', headerName: 'Message', width: 500, flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header
        title="LOGS"
        subtitle="System Logs Overview"
      />
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Logs" />
        <Tab label="Active Listing Logs" />
      </Tabs>
      {activeTab === 0 && (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={logs}
            columns={BQ_Log_Columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
      {activeTab === 1 && (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
          }}
        >
          <DataGrid
            rows={activeListingLogs}
            columns={ListingActivityLogColumns} 
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Logs;
