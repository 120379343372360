// TODO: Add a row for new lines: https://blog.ag-grid.com/add-new-rows-using-a-pinned-row-at-the-top-of-the-grid/

import React, { useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const SizeVariationsPage = () => {
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();

  const columnDefs = [
    { field: 'product', headerName: 'Product', width: 200, filter: true, editable: true },
    { field: 'country', headerName: 'Country', width: 125, filter: true, editable: true },
    { field: 'size', headerName: 'Size', width: 150, filter: true, editable: true },
    { field: 'product_country_size', headerName: 'Product Country Size', width: 200, filter: true, editable: false, hide:true },
    { field: 'size_name', headerName: 'Size Name', width: 200, filter: true, editable: true },
    { field: 'size_cm', headerName: 'Size (cm)', width: 150, filter: true, editable: true },
    { field: 'size_inch', headerName: 'Size (inch)', width: 150, filter: true, editable: true },
    { field: 'variable1', headerName: 'Variable 1', width: 150, filter: true, editable: true },
    { field: 'variable2', headerName: 'Variable 2', width: 150, filter: true, editable: true },
    { field: 'variable3', headerName: 'Variable 3', width: 150, filter: true, editable: true },
    { field: 'variable4', headerName: 'Variable 4', width: 150, filter: true, editable: true },
  ];

  const handleFetchData = useCallback(() => {
    fetch('/api/catalog/size_variations/all')
      .then((response) => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setRowData(data);
        } else {
          console.error('Fetched data is not an array:', data);
          setRowData([]); // Set to empty array if data is not as expected
        }
      })
      .catch((error) => console.error('Error fetching size variations data:', error));
  }, []);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  function onCellValueChanged(event) {
    const updatedRow = event.data;
    console.log('Updated row:', updatedRow);
    const api_endpoint = `/api/catalog/size_variations/update/`
    console.log('API endpoint:', api_endpoint);
    fetch(api_endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedRow),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Update successful:', data);
    })
    .catch(error => {
        console.error('Error updating size variation:', error);
    });
}

  const handleAddNewSizeVariation = () => {
    // Navigate to the form page with an empty form
    navigate('/catalog_size_variations/form');
  };

  return (
    <div>
      <Header title="Size Variations Content" />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddNewSizeVariation}
        style={{ margin: '10px' }}
      >
        Add New Size Variation
      </Button>
      <div className="ag-theme-quartz" style={{ height: '600px', padding: '5px', width: 'auto' }}>
        <AgGridReact 
          columnDefs={columnDefs} 
          rowData={rowData} 
          onCellValueChanged={onCellValueChanged}
          editType="fullRow"
        />
      </div>
    </div>
  );
};

export default SizeVariationsPage;