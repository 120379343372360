import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';

import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import Header from '../../components/Header';

const SkuFulfilmentFee = () => {
  const [sku, setSku] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [rowData, setRowData] = useState([]);
  const [fetchFeedback, setFetchFeedback] = useState({ message: '', style: {} });
  const gridRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const yy = String(date.getFullYear()).slice(-2);
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yy}-${mm}-${dd}`;
  };

  const columnDefs = [
    { 
      field: 'ob_date', 
      headerName: 'Date', 
      width: 100,
      valueFormatter: (params) => formatDate(params.value),
      cellStyle: { textAlign: 'right' }
    },
    { field: 'country_code', headerName: 'Country', width: 100 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'line', headerName: 'Line', width: 100 },
    { field: 'size', headerName: 'Size', width: 100 },
    { field: 'Color_Design', headerName: 'Color', width: 100 },
    { field: 'pcs_pack', headerName: 'Pcs/Pack', width: 100, cellStyle: { textAlign: 'right' } },
    { field: 'order_id', headerName: 'Order ID', width: 200 },
    { field: 'quantity_shipped', headerName: 'Qty', width: 100, cellStyle: { textAlign: 'right' } },
    { 
      field: 'FBAPerUnitFulfillmentFee', 
      headerName: 'Fulfillment Fee', 
      width: 150, 
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => params.value ? params.value.toFixed(2) : '0.00'
    },
  ];
  const handleFetchData = () => {
    setFetchFeedback({ message: 'Fetching data...', style: { color: 'blue' } });
    fetch('/api/fulfilment_fee/sku', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sku: sku.trim(), currency: currency }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRowData(data);
          gridRef.current.api.setRowData(data);
          setFetchFeedback({ message: 'Data fetched successfully', style: { color: 'green' } });
        } else {
          console.error('Unexpected response format:', data);
          setFetchFeedback({ message: 'Unexpected response format', style: { color: 'orange' } });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setFetchFeedback({ message: 'Error fetching data', style: { color: 'red' } });
      });
  };

  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv();
  };
  return (
    <div>
      <Header title="SKU Fulfilment Fee" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px auto', width: '95%' }}>
        <FormControl variant="outlined" style={{ width: '100%', marginBottom: '10px' }}>
          <InputLabel>Currency</InputLabel>
          <Select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            label="Currency"
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="GBP">GBP</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="INR">INR</MenuItem>
            <MenuItem value="JPY">JPY</MenuItem>
            <MenuItem value="CAD">CAD</MenuItem>
            <MenuItem value="MXN">MXN</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="SKU"
          variant="outlined"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchData}
            style={{ marginRight: '10px' }}
          >
            Fetch Data
          </Button>
          <Typography variant="body2" color="textSecondary" style={{ marginLeft: '10px', ...fetchFeedback.style }}>
            {fetchFeedback.message}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportCSV}
          >
            Export to CSV
          </Button>
        </div>
      </div>
      <div id="myGrid" className="ag-theme-quartz" style={{ height: '600px', width: '95%', margin: '20px auto', paddingBottom: '20px' }}>
        <AgGridReact
          ref={gridRef}
          enableRangeSelection={true}
          copyHeadersToClipboard={true}
          popupParent={document.body}
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </div>
    </div>
  );
};
export default SkuFulfilmentFee;
