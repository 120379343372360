import React, { useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import 'ag-grid-enterprise';

const LinesPage = () => {
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();

  const columnDefs = [
    { field: 'country', headerName: 'Country', width: 125, filter: true},
    { field: 'line', headerName: 'Line', width: 150, filter: true },
    { field: 'pcs_pack', headerName: 'Pcs/Pack', width: 125, filter: true },
    { field: 'version', headerName: 'Version', width: 125, filter: true },
    {
        field: 'timestamp',
        headerName: 'Last Updated',
        width: 200,
        valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
        },
    },
    {
        field: 'edit',
        headerName: 'Edit',
        width: 100,
        cellRenderer: (params) => (
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditLine(params.data)}
        >
            Edit
        </Button>
        ),
    },
  ];

  const handleFetchData = useCallback(() => {
    fetch('/api/catalog/lines/all')
      .then((response) => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setRowData(data);
        } else {
          console.error('Fetched data is not an array:', data);
          setRowData([]); // Set to empty array if data is not as expected
        }
      })
      .catch((error) => console.error('Error fetching lines data:', error));
  }, []);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  const handleEditLine = (line) => {
    console.log("Navigating with line data:", line); // Add this to check what is being passed
    navigate('/catalog_lines/form', { state: line });
  };

  const handleAddNewLine = () => {
    // Navigate to the form page with an empty form
    navigate('/catalog_lines/form');
  };

  return (
    <div>
      <Header title="Line Content"/>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddNewLine}
        style={{ margin: '10px' }}
      >
        Add New Line
      </Button>
      <div className="ag-theme-quartz" style={{ height: '600px', padding: '5px', width: 'auto' }}>
        <AgGridReact columnDefs={columnDefs} rowData={rowData} />
      </div>
    </div>
  );
};

export default LinesPage;