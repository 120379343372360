import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Header from '../../components/Header';

const Variations = () => {
  const [asins, setAsins] = useState('');
  const [country, setCountry] = useState('US');
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  const columnDefs = [
    { field: 'asin', headerName: 'ASIN', width: 150 },
    { field: 'brand', headerName: 'Brand', width: 150 },
    { field: 'item_name', headerName: 'Item Name', width: 250 },
    { field: 'color', headerName: 'Color', width: 100 },
    { field: 'size', headerName: 'Size', width: 100 },
    { field: 'packageQuantity', headerName: 'Package Quantity', width: 150 },
  ];

  const gridOptions = {
    defaultColDef: {
      editable: true,
      minWidth: 100,
      flex: 1,
    },
    suppressExcelExport: true,
    popupParent: document.body,
    columnDefs: columnDefs,
    rowData: rowData,
  };

  const handleFetchVariations = () => {
    const processedAsins = asins
      .split(/[\n, ]/) // Split by newlines, commas, and spaces
      .map(asin => asin.trim()) // Trim spaces
      .filter(asin => asin); // Remove empty strings
    fetch('/api/amazon_tools/variations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ child_asins: processedAsins, country: country }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRowData(data);
          gridRef.current.api.setGridOption('rowData', data);
          // gridRef.current.api.setRowData(data);
          // gridRef.current.api.updateGridOptions({ rowData: data });
          // console.log(data)
        } else {
          console.error('Unexpected response format:', data);
        }
      })
      .catch((error) => console.error('Error fetching variations:', error));
  };

  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv();
  };
  return (
    <div>
      <Header title="Get Variations" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0' }}>
        <FormControl variant="outlined" style={{ width: '80%', marginBottom: '10px' }}>
          <InputLabel>Country</InputLabel>
          <Select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            label="Country"
          >
            <MenuItem value="US">US</MenuItem>
            <MenuItem value="CA">CA</MenuItem>
            <MenuItem value="UK">UK</MenuItem>
            <MenuItem value="DE">DE</MenuItem>
            <MenuItem value="FR">FR</MenuItem>
            <MenuItem value="IT">IT</MenuItem>
            <MenuItem value="ES">ES</MenuItem>
            <MenuItem value="IN">IN</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="ASINs"
          multiline
          rows={4}
          variant="outlined"
          value={asins}
          onChange={(e) => setAsins(e.target.value)}
          style={{ width: '80%', marginBottom: '10px' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchVariations}
            style={{ marginRight: '10px' }}
          >
            Fetch All Variations
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportCSV}
          >
            Export to CSV
          </Button>
        </div>
      </div>
      <div id="myGrid" className="ag-theme-quartz" style={{ height: '600px', width: '80%', margin: '20px auto', paddingBottom: '20px' }}>
        <AgGridReact
          ref={gridRef}
          gridOptions={gridOptions}
        />
      </div>
    </div>
  );
};
export default Variations;