import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const DAM = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);

  const fetchData = useCallback(() => {
    console.log('Initiating DAM data fetch');
    fetch('/api/dam/get_dam')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          setRowData(data);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching DAM data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(() => {
    if (rowData.length === 0) return [];
    const orderedColumns = ['sku', ...Array.from({length: 20}, (_, i) => `path${i+1}`)];
    return orderedColumns.map(key => ({
      headerName: key === 'sku' ? 'SKU' : `Path ${key.slice(4)}`,
      field: key,
      sortable: true,
      filter: true,
      resizable: true,
    })).filter(col => rowData[0].hasOwnProperty(col.field));
  }, [rowData]);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'filters',
    };
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Digital Asset Management</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
            }}
            enableRangeSelection={true}
            copyHeadersToClipboard={false}
            pagination={true}
            paginationPageSize={50}
            sideBar={sideBar}
          />
        </div>
      )}
    </div>
  );
};

export default DAM;