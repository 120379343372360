import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Box, Typography } from '@mui/material';

const Returns = () => {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    fetch('/api/orders/returns_reasons')
      .then(response => response.json())
      .then(data => setRowData(data));
  }, []);

  const columns = [
    { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 125 },
    { headerName: "Line", field: "Line", sortable: true, filter: true, width: 125 },
    { headerName: "Color", field: "Color_Design", sortable: true, filter: true, width: 200 },
    { headerName: "Size", field: "Size", sortable: true, filter: true, width: 125 },
    { headerName: "Pcs Pack", field: "pcs_pack", sortable: true, filter: true, width: 125 },
    { headerName: "Return Date", field: "return_date", sortable: true, filter: true, width: 150, valueFormatter: params => new Date(params.value).toISOString().split('T')[0] },
    { headerName: "Customer Comments", field: "customer_comments", sortable: true, filter: true, width: 400 }
  ];

  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'none'
    };
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Returns Reasons</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columns}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          sideBar={sideBar}
          enableRangeSelection={true}
          multiSortKey="ctrl"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[50, 100, 500, 1000]}
        />
      </div>
    </div>
  );
};

export default Returns;

