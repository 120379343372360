import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const LineDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [quickFilter, setQuickFilter] = useState('');
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');

  useEffect(() => {
    document.title = "Line Details | Encasa HQ";
    console.log('Initiating Line Details data fetch');
    fetch('/api/line-details/get-details')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          setRowData(data);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Details data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const getRatioValue = (numeratorField, denominatorField) => ({
    aggFunc: params => {
      let totalNumerator = 0, totalDenominator = 0;
      params.values.forEach(value => { if (value && typeof value === 'object') { totalNumerator += value[numeratorField] || 0; totalDenominator += value[denominatorField] || 0; } });
      return { [numeratorField]: totalNumerator, [denominatorField]: totalDenominator, ratio: totalDenominator > 0 ? totalNumerator / totalDenominator : 0 };
    },
    valueGetter: params => {
      const numerator = params.data[numeratorField] || 0, denominator = params.data[denominatorField] || 0;
      return { [numeratorField]: numerator, [denominatorField]: denominator, ratio: denominator > 0 ? numerator / denominator : 0 };
    }
  });

  const columns = [
    {
      headerName: 'Product Information',
      children: [
        { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
        { headerName: "Line", field: "line", sortable: true, filter: true, width: 110, pinned: 'left', enableRowGroup: true },
        { headerName: "Region", field: "region", sortable: true, filter: true, width: 125, enableRowGroup: true },
        { headerName: "Channel", field: "sales_channel", sortable: true, filter: true, width: 125, enableRowGroup: true },
        { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 100, enableRowGroup: true },
        { headerName: "Group", field: "group", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Segment", field: "segment", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Currency", field: "currency", sortable: true, filter: true, width: 100, columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'Sales Data',
      children: [
        { headerName: "P7 Sales", field: "P7_sales", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "L7 Sales", field: "l7_sales", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "P7 MA", field: "P7_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "L7 MA", field: "l7_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "L30 MA", field: "l30_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "L90 MA", field: "l90_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "INSMA", field: "insma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "MA", field: "ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "Max MA", field: "max_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
      ]
    },
    {
      headerName: 'Inventory',
      children: [
        { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "MFN Stock", field: "mfn_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "FC Stock", field: "fc_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
      ]
    },
    {
      headerName: 'Performance',
      children: [
        { headerName: "L15 Returns", field: "L15_returns", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "MTD Sold", field: "mtd_sold", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "L7 Profit", field: "l7_profit", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', aggFunc: 'sum' },
      ]
    },
    {
      headerName: 'SP Ads (Last 7 Days)',
      children: [
        { headerName: "Impressions", field: "l7_impressions", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Clicks", field: "l7_clicks", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Spend", field: "l7_cost", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 2})}` : '', aggFunc: 'sum' },
        { headerName: "Sales", field: "l7_sales_1", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 2})}` : '', aggFunc: 'sum' },
        { headerName: "Orders", field: "l7_orders", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "CTR", field: "l7_ctr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${(params.value * 100).toFixed(2)}%` : '', ...getRatioValue('l7_clicks', 'l7_impressions') },
        { headerName: "CVR", field: "l7_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${(params.value * 100).toFixed(2)}%` : '', ...getRatioValue('l7_orders', 'l7_clicks') },
        { headerName: "ACoS", field: "l7_acos", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${(params.value * 100).toFixed(2)}%` : '', ...getRatioValue('l7_cost', 'l7_sales_1') },
      ]
    },
    {
      headerName: 'Business Reports',
      children: [
        { headerName: "Page Views", field: "total_page_views", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Sessions", field: "total_sessions", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Orders", field: "total_orders", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Units", field: "total_units", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Sales", field: "total_sales", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 2})}` : '', aggFunc: 'sum' },
        { headerName: "CVR", field: "recalculated_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${(params.value * 100).toFixed(2)}%` : '', ...getRatioValue('total_orders', 'total_sessions') },
      ]
    },
    {
      headerName: 'Last 7 Days',
      children: Array.from({ length: 7 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i - 1);
        const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
        return { 
          headerName: formattedDate, 
          field: `_${i + 1}`, 
          sortable: true, filter: 'agNumberColumnFilter', width: 100,
          cellStyle: { textAlign: 'right' },
          aggFunc: 'sum',
          valueGetter: params => params.data ? params.data[`_${i + 1}`] : undefined,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
        };
      }),
    },
    {
      headerName: 'Last 24 Months',
      children: Array.from({ length: 24 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' });
        return { 
          headerName: formattedDate, 
          field: `_l${i + 1}`,
          sortable: true, 
          filter: 'agNumberColumnFilter',
          width: 100,
          cellStyle: { textAlign: 'right' },
          columnGroupShow: i < 6 ? undefined : 'open',
          aggFunc: 'sum',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
        };
      }),
    },
  ];

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilter('');
    setAppliedQuickFilter('');
  }, []);

  const handleQuickFilterChange = useCallback((event) => {
    setQuickFilter(event.target.value);
  }, []);

  const handleQuickFilterKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      setAppliedQuickFilter(quickFilter);
    }
  }, [quickFilter]);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Details</Typography>
        <Box>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Quick Filter..."
            value={quickFilter}
            onChange={handleQuickFilterChange}
            onKeyPress={handleQuickFilterKeyPress}
            style={{ marginRight: '10px', width: '200px' }}
          />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV} style={{ marginRight: '10px' }}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={[50, 100, 500, 1000]}
            paginationNumberFormatter={(params) => {
              return '[' + params.value.toLocaleString() + ']';
            }}
            quickFilterText={appliedQuickFilter}
            cacheQuickFilter={true}
          />
        </div>
      )}
    </div>
  );
};

export default LineDetails;