import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, TextField, Button, Paper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const KeywordExplorer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState('');
  const [numKeywords, setNumKeywords] = useState(30);
  const [similarKeywords, setSimilarKeywords] = useState([]);
  const [newKeywords, setNewKeywords] = useState('');
  const [feedback, setFeedback] = useState('');
  const gridRef = useRef(null);

  useEffect(() => {
    document.title = "Keyword Explorer | Encasa HQ";
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleGetSimilarKeywords = async () => {
    try {
      const response = await fetch('/api/keyword_explorer/find_similar_keywords', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query, n: parseInt(numKeywords, 10) }),
      });
      const data = await response.json();
      setSimilarKeywords(data.similar_keywords.map(([kw, score], index) => ({
        id: index,
        keyword: kw,
        similarity: score.toFixed(4) // Round to 4 decimal places
      })));
    } catch (error) {
      console.error('Error fetching similar keywords:', error);
      setFeedback('Error fetching similar keywords');
    }
  };

  const handleAddKeywords = async () => {
    try {
      const keywords = newKeywords.split('\n').map(kw => kw.trim()).filter(kw => kw);
      const response = await fetch('/api/keyword_explorer/add_keywords', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ keywords }),
      });
      const data = await response.json();
      setFeedback(data.message);
      setNewKeywords('');
    } catch (error) {
      console.error('Error adding keywords:', error);
      setFeedback('Error adding keywords');
    }
  };

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const columns = [
    { headerName: 'Keyword', field: 'keyword', sortable: true, filter: true, flex: 2 },
    { 
      headerName: 'Similarity Score', 
      field: 'similarity', 
      sortable: true, 
      filter: true,
      flex: 1,
      valueFormatter: (params) => Number(params.value).toFixed(3),
      cellStyle: { textAlign: 'right' }
    }
  ];

  return (
    <Box m="20px">
      <Typography variant="h4" fontWeight="bold" mb="20px">
        Keyword Explorer
      </Typography>

      <Paper elevation={3} sx={{ mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Get Similar Keywords" />
          <Tab label="Add Keywords" />
        </Tabs>
      </Paper>

      {activeTab === 0 && (
        <Box>
          <Box display="flex" flexDirection="column" mb={3}>
            <TextField
              label="Enter the product description"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ mb: 2 }}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <TextField
                type="number"
                label="Number of keywords"
                value={numKeywords}
                onChange={(e) => setNumKeywords(parseInt(e.target.value, 10) || '')}
                sx={{ width: '200px' }}
                variant="outlined"
              />
              <Box>
                <Button variant="contained" onClick={handleGetSimilarKeywords} sx={{ mr: 2 }}>
                  Get Similar Keywords
                </Button>
                <Button variant="outlined" onClick={handleExportCSV}>
                  Export CSV
                </Button>
              </Box>
            </Box>
          </Box>
          <Box height="600px" width="100%" className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              columnDefs={columns}
              rowData={similarKeywords}
              pagination={true}
              paginationPageSize={100}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
              }}
              enableRangeSelection={true}
              copyHeadersToClipboard={true}
              suppressCopyRowsToClipboard={false}
            />
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box>
          <TextField
            fullWidth
            multiline
            rows={20}
            label="Enter keywords (one per line)"
            value={newKeywords}
            onChange={(e) => setNewKeywords(e.target.value)}
            sx={{ mb: 2 }}
            variant="outlined"
          />
          <Button variant="contained" onClick={handleAddKeywords}>
            Add Keywords
          </Button>
          {feedback && (
            <Typography sx={{ mt: 2 }} color="primary">
              {feedback}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default KeywordExplorer;