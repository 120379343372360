import React from 'react';

const AlertPillsCellRenderer = (props) => {
  // Ensure alerts is always an array
  const alerts = Array.isArray(props.value) ? props.value : 
                 (props.value ? [props.value] : []);
  
  const getAlertColor = (severity) => {
    switch (severity) {
      case 'critical':
        return '#d32f2f';  // Red for critical alerts
      case 'warning':
        return '#f57c00';  // Orange for warnings
      case 'info':
        return '#1976d2';  // Blue for info alerts
      default:
        return '#757575';  // Grey for unknown severity
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
      {alerts.map((alert, index) => {
        // Handle both object and string alerts
        const message = typeof alert === 'object' ? alert.message : alert;
        const severity = typeof alert === 'object' ? alert.severity : 'info';
        
        return (
          <span key={index} 
                style={{
                  display: 'inline-block',
                  padding: '2px 6px',
                  borderRadius: '10px',
                  fontSize: '0.7rem',
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: getAlertColor(severity),
                  boxShadow: '0 1px 2px rgba(0,0,0,0.15), 0 1px 1px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s ease-in-out',
                  margin: '1px',
                  lineHeight: '1.2',
                }}
                title={`${severity}: ${message}`}
          >
            {message}
          </span>
        );
      })}
    </div>
  );
};

export default AlertPillsCellRenderer;