import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const LineMonthly = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const [quickFilter, setQuickFilter] = useState('');
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
  const gridRef = useRef(null);

  useEffect(() => {
    document.title = "Line Monthly | Encasa HQ";
    console.log('Initiating Line Monthly data fetch');
    fetch('/api/line/get_line_monthly_data')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          setRowData(data);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Monthly data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const columns = [
    { 
      headerName: "Country", 
      field: "country", 
      sortable: true, 
      filter: true, 
      width: 120, 
      pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value
    },
    { 
      headerName: "Line", 
      field: "Line", 
      sortable: true, 
      filter: true, 
      width: 120, 
      pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value
    },
    { 
      headerName: "Metric", 
      field: "metric", 
      sortable: true, 
      filter: true, 
      width: 150, 
      pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value
    },
    { 
      headerName: "DRI", 
      field: "dri", 
      sortable: true, 
      filter: true, 
      width: 100, 
      enableRowGroup: true,
      getQuickFilterText: params => params.value
    },
    ...Array.from({ length: 12 }, (_, i) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - i);
      const month = currentDate.toLocaleString('default', { month: 'short' });
      const year = currentDate.getFullYear().toString().slice(-2);
      const monthLabel = `${month} ${year}`;
      return {
        headerName: monthLabel,
        field: `month_${i + 1}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        width: 120,
        cellStyle: { textAlign: 'right' },
        aggFunc: 'sum',
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getQuickFilterText: () => ''  // Disable quick filter for these columns
      };
    }),
  ];

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilter('');
    setAppliedQuickFilter('');
  }, []);

  const handleQuickFilterChange = useCallback((event) => {
    setQuickFilter(event.target.value);
  }, []);

  const handleQuickFilterKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      setAppliedQuickFilter(quickFilter);
    }
  }, [quickFilter]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: null,
    };
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Monthly</Typography>
        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Quick Filter..."
            value={quickFilter}
            onChange={handleQuickFilterChange}
            onKeyPress={handleQuickFilterKeyPress}
            style={{ marginRight: '10px', width: '200px' }}
          />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              cellRenderer: 'agCellRendererHtml',
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            grandTotalRow="bottom"
            groupDefaultExpanded={0} // Collapse all groups by default
            autoGroupColumnDef={{
              headerName: 'Group',
              minWidth: 250,
              cellRendererParams: {
                suppressCount: true,
              },
            }}
            quickFilterText={appliedQuickFilter}
            cacheQuickFilter={true}
          />
        </div>
      )}
    </div>
  );
};

export default LineMonthly;