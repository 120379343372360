import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import Header from '../../components/Header';

const LineFulfilmentFee = () => {
  const [line, setLine] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [rowData, setRowData] = useState([]);
  const [fetchFeedback, setFetchFeedback] = useState({ message: '', style: {} });
  const gridRef = useRef(null);
  const navigate = useNavigate();

  const columnDefs = [
    { field: 'seller_sku', headerName: 'Seller SKU', width: 200, filter: true },
    { field: 'asin', headerName: 'ASIN', width: 150, filter: true },
    { field: 'currency', headerName: 'Currency', width: 125, filter: true },
    { field: 'line', headerName: 'Line', width: 100, filter: true },
    { field: 'size', headerName: 'Size', width: 100, filter: true },
    { field: 'Color_Design', headerName: 'Color', width: 100, filter: true },
    { field: 'pcs_pack', headerName: 'Pcs/Pack', width: 120, cellStyle: { textAlign: 'right' }, filter: true },
    { 
      field: 'mode_fee', 
      headerName: 'Mode Fee', 
      width: 120, 
      cellStyle: { textAlign: 'right' }, 
      filter: true,
      valueFormatter: (params) => params.value ? params.value.toFixed(2) : '0.00'
    },
    { 
      field: 'ma', 
      headerName: 'MA', 
      width: 100, 
      cellStyle: { textAlign: 'right' }, 
      filter: true,
      valueFormatter: (params) => params.value ? params.value.toFixed(2) : '0.00'
    },
    {
      field: 'monthly_fee',
      headerName: 'Monthly Fee',
      width: 150,
      cellStyle: { textAlign: 'right' },
      type: 'numericColumn',
      valueGetter: (params) => {
        const modeFee = params.data.mode_fee || 0;
        const ma = params.data.ma || 0;
        return modeFee * ma * 30;
      },
      valueFormatter: (params) => params.value.toFixed(2)
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      cellRenderer: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRowButtonClick(params.data)}
        >
          Go to SKU
        </Button>
      )
    },
  ];

  const handleFetchData = useCallback(() => {
    setFetchFeedback({ message: 'Fetching data...', style: { color: 'blue' } });
    fetch('/api/fulfilment_fee/line', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ line: line.trim(), currency: currency }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRowData(data);
          setFetchFeedback({ message: 'Data fetched successfully', style: { color: 'green' } });
        } else {
          console.error('Unexpected response format:', data);
          setFetchFeedback({ message: 'Unexpected response format', style: { color: 'orange' } });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setFetchFeedback({ message: 'Error fetching data', style: { color: 'red' } });
      });
  }, [line, currency]);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleRowButtonClick = useCallback((rowData) => {
    window.open(`/fulfilment_fee/sku?sku=${rowData.seller_sku}&currency=${rowData.currency}`, '_blank');
  }, []);
  
  return (
    <div>
      <Header title="Line Fulfilment Fee" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px auto', width: '95%' }}>
        <FormControl variant="outlined" style={{ width: '100%', marginBottom: '10px' }}>
          <InputLabel>Currency</InputLabel>
          <Select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            label="Currency"
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="GBP">GBP</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="INR">INR</MenuItem>
            <MenuItem value="JPY">JPY</MenuItem>
            <MenuItem value="CAD">CAD</MenuItem>
            <MenuItem value="MXN">MXN</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Line"
          variant="outlined"
          value={line}
          onChange={(e) => setLine(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchData}
            style={{ marginRight: '10px' }}
          >
            Fetch Data
          </Button>
          <Typography variant="body2" color="textSecondary" style={{ marginLeft: '10px', ...fetchFeedback.style }}>
            {fetchFeedback.message}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportCSV}
          >
            Export to CSV
          </Button>
        </div>
      </div>
      <div id="myGrid" className="ag-theme-quartz" style={{ height: '600px', width: '95%', margin: '20px auto', paddingBottom: '20px' }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          enableRangeSelection={true}
          copyRangeDown={true}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  );
};

export default LineFulfilmentFee;