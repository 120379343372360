// /spapi_getlistingdata/spapiDataContext.js

import React, { createContext, useState, useContext } from 'react';

const SpApiDataContext = createContext();

export const SpApiDataProvider = ({ children }) => {
  const [spApiData, setSpApiData] = useState(null);
  const [latestStatusData, setLatestStatusData] = useState(null);

  return (
    <SpApiDataContext.Provider value={{ 
      spApiData, setSpApiData, 
      latestStatusData, setLatestStatusData 
    }}>
      {children}
    </SpApiDataContext.Provider>
  );
};

export const useSpApiData = () => useContext(SpApiDataContext);
