import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';

const FormPage = () => {
  const location = useLocation();
  console.log("Received state:", location.state); // Log to check received state

  const [initialValues, setInitialValues] = useState({
    country: '',
    line: '',
    pcs_pack: '',
    version: '',
    brand: '',
    title: '',
    bp1: '',
    bp2: '',
    bp3: '',
    bp4: '',
    bp5: '',
    description: '',
    keywords: '',
    size_name: '',
    parent_sku: '',
    product_type: '',
    variation_theme: '',
    browse_node: '',
    timestamp: ''
  });

  useEffect(() => {
    if (location.state) {
      setInitialValues({
        country: location.state.country || '',
        line: location.state.line || '',
        pcs_pack: location.state.pcs_pack || '',
        version: location.state.version || '',
        brand: location.state.brand || '',
        title: location.state.title || '',
        bp1: location.state.bp1 || '',
        bp2: location.state.bp2 || '',
        bp3: location.state.bp3 || '',
        bp4: location.state.bp4 || '',
        bp5: location.state.bp5 || '',
        description: location.state.description || '',
        keywords: location.state.keywords || '',
        size_name: location.state.size_name || '',
        parent_sku: location.state.parent_sku || '',
        product_type: location.state.product_type || '',
        variation_theme: location.state.variation_theme || '',
        browse_node: location.state.browse_node || '',
        timestamp: location.state.timestamp || ''
      });
    } else {
      // Set to default values if no state is passed (new entry scenario)
      setInitialValues({
        country: '',
        line: '',
        pcs_pack: '',
        version: '',
        brand: '',
        title: '',
        bp1: '',
        bp2: '',
        bp3: '',
        bp4: '',
        bp5: '',
        description: '',
        keywords: '',
        size_name: '',
        parent_sku: '',
        product_type: '',
        variation_theme: '',
        browse_node: '',
        timestamp: ''
      });
    }
  }, [location.state]);

  const isNonMobile = useMediaQuery('(min-width:600px)');

  const fetchData = async (country, line, pcs_pack, version) => {
    // if (country && line && pcs_pack && version) {
      try {
        const response = await fetch(`/api/catalog/lines/fetch?country=${country}&line=${line}&pcs_pack=${pcs_pack}&version=${version}`);
        const data = await response.json();
        console.log('Fetched data:', data); // Log the fetched data
        setInitialValues(data[0]); // Update the form fields with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    //}
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await fetch('/api/catalog/lines/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      const result = await response.json();
      console.log('Update result:', result);
      resetForm();
    } catch (error) {
      console.error('Error updating data:', error);
    }
    setSubmitting(false);
  };



  return (
    <Box m="10px">
      <Header title="Edit Line Content"/>
      <Box display="grid" gridTemplateColumns="60% 35%" gap="20px">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ isSubmitting, values, setFieldValue }) => (
            <>
              <Box>
              <Form>
              <Box
                display="grid"
                gap="10px"
                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 5' },
                }}
              >
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="country"
                  label="Country"
                  value={values.country}
                  onChange={(e) => {
                    setFieldValue('country', e.target.value);
                  }}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="line"
                  label="Line"
                  value={values.line}
                  onChange={(e) => {
                    setFieldValue('line', e.target.value);
                  }}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="pcs_pack"
                  label="Pcs Pack"
                  value={values.pcs_pack}
                  onChange={(e) => {
                    setFieldValue('pcs_pack', e.target.value);
                  }}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="version"
                  label="Version"
                  value={values.version}
                  onChange={(e) => {
                    setFieldValue('version', e.target.value);
                  }}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                      console.log("Country:", values.country);
                      console.log("Line:", values.line);
                      console.log("Pcs Pack:", values.pcs_pack);
                      console.log("version:", values.version);
                      const { country, line, pcs_pack, version } = values;
                      //if (country && line && pcs_pack && version) {
                      fetchData(country, line, pcs_pack, version);
                      //}
                  }}
                  sx={{ gridColumn: 'span 1' }}
                  >
                  Load
                  </Button>
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="brand"
                  label="Brand"
                  value={values.brand}
                  onChange={(e) => setFieldValue('brand', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="title"
                  label="Title"
                  value={values.title}
                  onChange={(e) => setFieldValue('title', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.title.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={2}
                  name="bp1"
                  label="Bullet Point 1"
                  value={values.bp1}
                  onChange={(e) => setFieldValue('bp1', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.bp1.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={2}
                  name="bp2"
                  label="Bullet Point 2"
                  value={values.bp2}
                  onChange={(e) => setFieldValue('bp2', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.bp2.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={2}
                  name="bp3"
                  label="Bullet Point 3"
                  value={values.bp3}
                  onChange={(e) => setFieldValue('bp3', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.bp3.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={2}
                  name="bp4"
                  label="Bullet Point 4"
                  value={values.bp4}
                  onChange={(e) => setFieldValue('bp4', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.bp4.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={2}
                  name="bp5"
                  label="Bullet Point 5"
                  value={values.bp5}
                  onChange={(e) => setFieldValue('bp5', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.bp5.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={5}
                  name="description"
                  label="Description"
                  value={values.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.description.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  multiline
                  rows={2}
                  name="keywords"
                  label="Backend Keywords"
                  value={values.keywords}
                  onChange={(e) => setFieldValue('keywords', e.target.value)}
                  sx={{ gridColumn: 'span 5' }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {values.keywords.length}
                      </Typography>
                    )
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="parent_sku"
                  label="Parent SKU"
                  value={values.parent_sku}
                  onChange={(e) => setFieldValue('parent_sku', e.target.value)}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="size_name"
                  label="Size Name"
                  value={values.size_name}
                  onChange={(e) => setFieldValue('size_name', e.target.value)}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="product_type"
                  label="Product Type"
                  value={values.product_type}
                  onChange={(e) => setFieldValue('product_type', e.target.value)}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="variation_theme"
                  label="Variation Theme"
                  value={values.variation_theme}
                  onChange={(e) => setFieldValue('variation_theme', e.target.value)}
                  sx={{ gridColumn: 'span 1' }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  variant="filled"
                  size="small"
                  type="text"
                  name="browse_node"
                  label="Browse Node"
                  value={values.browse_node}
                  onChange={(e) => setFieldValue('browse_node', e.target.value)}
                  sx={{ gridColumn: 'span 1' }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px">
                <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
                  Loaded Version Timestamp: {new Date(initialValues.timestamp).toLocaleString('en-IN', {
                    timeZone: 'Asia/Kolkata',
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                  })}
                </Typography>
                <Box sx={{ mb: '10px' }}>
                  <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                    Save
                  </Button>
                </Box>
              </Box>
              </Form>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}><strong>Preview</strong></Typography>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Title</strong></TableCell>
                      <TableCell>{values.title}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Mobile Title</strong></TableCell>
                      <TableCell>{values.title.substring(0, 70)}...</TableCell>                    
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Bullet Point 1</strong></TableCell>
                      <TableCell>{values.bp1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Bullet Point 2</strong></TableCell>
                      <TableCell>{values.bp2}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Bullet Point 3</strong></TableCell>
                      <TableCell>{values.bp3}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Bullet Point 4</strong></TableCell>
                      <TableCell>{values.bp4}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Bullet Point 5</strong></TableCell>
                      <TableCell>{values.bp5}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Description</strong></TableCell>
                      <TableCell>{values.description}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: '100px' }}><strong>Backend Keywords</strong></TableCell>
                      <TableCell>{values.keywords}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default FormPage;