import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';

const SizeVariationFormPage = () => {
  const location = useLocation();
  console.log("Received state:", location.state); // Log to check received state

  const [initialValues, setInitialValues] = useState({
    product: '',
    country: '',
    size: '',
    product_country_size: '',
    size_name: '',
    size_cm: '',
    size_inch: '',
    variable1: '',
    variable2: '',
    variable3: '',
    variable4: '',
  });

  useEffect(() => {
    if (location.state) {
      setInitialValues({
        product: location.state.product || '',
        country: location.state.country || '',
        size: location.state.size || '',
        product_country_size: location.state.product_country_size || '',
        size_name: location.state.size_name || '',
        size_cm: location.state.size_cm || '',
        size_inch: location.state.size_inch || '',
        variable1: location.state.variable1 || '',
        variable2: location.state.variable2 || '',
        variable3: location.state.variable3 || '',
        variable4: location.state.variable4 || '',
      });
    } else {
      // Set to default values if no state is passed (new entry scenario)
      setInitialValues({
        product: '',
        country: '',
        size: '',
        product_country_size: '',
        size_name: '',
        size_cm: '',
        size_inch: '',
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
      });
    }
  }, [location.state]);

  const isNonMobile = useMediaQuery('(min-width:600px)');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await fetch('/api/catalog/size_variations/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      const result = await response.json();
      console.log('Update result:', result);
      resetForm();
    } catch (error) {
      console.error('Error updating data:', error);
    }
    setSubmitting(false);
  };

  return (
    <Box m="10px">
      <Header title="Edit Size Variation Content" />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
            >
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="product"
                label="Product"
                value={values.product}
                onChange={(e) => setFieldValue('product', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="country"
                label="Country"
                value={values.country}
                onChange={(e) => setFieldValue('country', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="size"
                label="Size"
                value={values.size}
                onChange={(e) => setFieldValue('size', e.target.value)}
              />
              <input
                type="hidden"
                name="product_country_size"
                value={`${values.product}-${values.country}-${values.size}`}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="size_name"
                label="Size Name"
                value={values.size_name}
                onChange={(e) => setFieldValue('size_name', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="size_cm"
                label="Size (cm)"
                value={values.size_cm}
                onChange={(e) => setFieldValue('size_cm', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="size_inch"
                label="Size (inch)"
                value={values.size_inch}
                onChange={(e) => setFieldValue('size_inch', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="variable1"
                label="Variable 1"
                value={values.variable1}
                onChange={(e) => setFieldValue('variable1', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="variable2"
                label="Variable 2"
                value={values.variable2}
                onChange={(e) => setFieldValue('variable2', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="variable3"
                label="Variable 3"
                value={values.variable3}
                onChange={(e) => setFieldValue('variable3', e.target.value)}
              />
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                size="small"
                type="text"
                name="variable4"
                label="Variable 4"
                value={values.variable4}
                onChange={(e) => setFieldValue('variable4', e.target.value)}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SizeVariationFormPage;